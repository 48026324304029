var isMobile = new Object();
isMobile.Android = function () {
	return navigator.userAgent.match(/Android/i);
};

isMobile.BlackBerry = function () {
	return navigator.userAgent.match(/BlackBerry/i);
};
isMobile.iOS = function () {
	return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

isMobile.iPad = function () {
	return navigator.userAgent.match(/iPad/i);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera Mini/i);
};
isMobile.Windows = function () {
	return navigator.userAgent.match(/IEMobile/i);
};

isMobile.Firefox = function () {
	return navigator.userAgent.match(/Firefox/gi);
};
isMobile.InternetExplorer = function () {
	return navigator.userAgent.match(/MSIE/gi);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera/gi);
};
isMobile.Safari = function () {
	return navigator.userAgent.match(/Safari/gi);
};
isMobile.any = function () {
	return (
		isMobile.Android() ||
		isMobile.BlackBerry() ||
		isMobile.iOS() ||
		isMobile.Opera() ||
		isMobile.Windows()
	);
};
if (isMobile.any() && isMobile.iPad() == null) {
	//return;
}

var ua = window.navigator.userAgent;
var is_ie = /MSIE|Trident/.test(ua);

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

var winWT = $(window).innerWidth();
var winHT = $(window).innerHeight();

var disableScrolling = function () {
	var x = window.scrollX;
	var y = window.scrollY;
	window.onscroll = function () { window.scrollTo(x, y); };
}
var enableScrolling = function () {
	window.onscroll = function () { };
}
window.onbeforeunload = function () {
	window.scrollTo(0, 0);
}

$(window).on('load', function () {
	//window.scrollTo(0, 0);
});

var winWT = $(window).width();

$(document).ready(() => {
	var mobNav = $("header nav").find('ul');
	var navWT = $(mobNav).innerWidth();
	$(".nav-icon").on('click', function () {
		//return;
		if ($(this).hasClass("open")) {
			resetMobileMenu();
		} else {
			$(this).addClass("open");
			$(mobNav).animate({ right: 0 }, 300);
			$('.menu_overlay').css({ display: 'block' });
			$('body').css({ overflow: 'hidden' });
		}
	});
	function resetMobileMenu() {
		$(".nav-icon").removeClass("open");
		$(mobNav).animate({ right: -navWT + "px" }, 300);
		$('.menu_overlay').css({ display: 'none' });
		$('body').css({ overflowY: 'scroll' });
	}
	function closeNavMobile() {
		if (winWT <= 11023) {
			$('nav a').on('click', function () {
				resetMobileMenu();
			});
		}
	}
	closeNavMobile();
	$('header .menu_overlay').on('click', function () {
		resetMobileMenu();
	});
	$(window).resize(function () {
		winWT = $(window).width();
		closeNavMobile();
		//resetMobileMenu();
	});

	/* $('.reg-open').on('click', function () {
		$('#registration-form').fadeIn(500);
		$('body').css({ 'overflow': 'hidden' });
	});
	$('#registration-form .bg-overlay').on('click', function () {
		$('#registration-form').fadeOut(500);
		$('body').css({ 'overflow': 'auto' });
	}); */
	/*======================================================================================*/
	/*======================================================================================*/

	function fileUploader() {
		$('input[type="file"]').on('click', function () {
			$(".file_names").html("");
		})
		if ($('input[type="file"]')[0]) {
			var fileInput = document.querySelector('label[for="et_pb_contact_brand_file_request_0"]');
			fileInput.ondragover = function () {
				this.className = "et_pb_contact_form_label changed";
				return false;
			}
			fileInput.ondragleave = function () {
				this.className = "et_pb_contact_form_label";
				return false;
			}
			fileInput.ondrop = function (e) {
				e.preventDefault();
				var fileNames = e.dataTransfer.files;
				for (var x = 0; x < fileNames.length; x++) {
					console.log(fileNames[x].name);
					$ = jQuery.noConflict();
					$('label[for="et_pb_contact_brand_file_request_0"]').append("<div class='file_names'>" + fileNames[x].name + "</div>");
				}
			}
			$('#et_pb_contact_brand_file_request_0').change(function () {
				var fileNames = $('#et_pb_contact_brand_file_request_0')[0].files[0].name;
				$('label[for="et_pb_contact_brand_file_request_0"]').append("<div class='file_names'>" + fileNames + "</div>");
				$('label[for="et_pb_contact_brand_file_request_0"]').css('background-color', '##eee9ff');
			});
		}
	}
	fileUploader();




});